<template>
    <div>
        <div class="pt-24 px-20">
            <div class="text-14 px-8 font-gibson-regular border-b bg-sand-10 border-bottom-navigation-line pb-12 sticky top-0 z-5 md:static">
                {{ $translate('Search.Overlay.SearchHistory') }}
            </div>
        </div>
        <div class="wrap suggestions-max-height">
            <RouterLink v-for="(item, i) in historySearches"
                        :key="i" 
                        :to="{ path: searchPage, query: { term: item } }"
                        class="pt-22 px-8 text-left pb-12 w-full border-b border-bottom-navigation-line block capitalize-first transition duration-300 hover:bg-sand-20"
                        @click.native="handleSuggestionClick(item)">
                {{ item }}
            </Routerlink>
        </div>
    </div>
</template>

<script lang="ts" setup>
import serverContext from '@/core/serverContext.service';
import useSearch from '../useSearch';
import trackingUtils from '@/project/tracking/tracking.utils';

defineProps<{
    historySearches?: string[];
}>();

const searchPage = serverContext.searchContext.batchSearchResultUrl;
const { handleNavigation } = useSearch();

const handleSuggestionClick = (term: string)=> {
    handleNavigation(term);
    trackingUtils.search.trackQuickLinkClick(term, 'latest');
};

</script>
