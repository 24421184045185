<template>
    <div class="flex flex-col items-start gap-4">
        <div class="flex items-center gap-8 font-gibson text-20 font-medium leading-26">
            <template v-if="selectedVariant.packageQuantity && selectedVariant?.packageQuantity > 1">
                <SplitTest feature-key="package-quantity-test"
                           default-variation="variation-1"
                           is-fallback>
                    {{ unitPrice }}
                </SplitTest>
                <SplitTest feature-key="package-quantity-test"
                           variation="variation-2"
                           default-variation="variation-1">
                    {{ selectedVariant.pricing?.packageComparison?.formatted }}
                </SplitTest>
                <SplitTest feature-key="package-quantity-test"
                           variation="variation-3"
                           default-variation="variation-1">
                    {{ selectedVariant.pricing?.package?.formatted }}
                </SplitTest>
            </template>
            <template v-else>
                {{ unitPrice }}
            </template>
            <ProductDetailsPriceAdditionalShippingInfo
                v-if="!isSticky && additionalShippingInfo && isEcomMarket"
                :details="details"
                class="text-14 leading-16"/>
        </div>
        <div v-if="comparisonPrice || ecoChargePrice || additionalShippingInfo || selectedVariant.pricing?.packageComparison?.formatted"
             class="flex flex-col items-start">
            <div v-if="comparisonPrice || selectedVariant.pricing?.packageComparison?.formatted"
                 class="font-gibson text-12 font-light leading-16 text-brown-60">
                <template v-if="selectedVariant.packageQuantity && selectedVariant?.packageQuantity > 1">
                    <SplitTest feature-key="package-quantity-test"
                               default-variation="variation-1"
                               is-fallback>
                        {{ comparisonPrice }}
                    </SplitTest>
                    <SplitTest feature-key="package-quantity-test"
                               variation="variation-2"
                               default-variation="variation-1">
                        {{ comparisonPrice }}
                    </SplitTest>
                    <SplitTest feature-key="package-quantity-test"
                               variation="variation-3"
                               default-variation="variation-1">
                        {{ selectedVariant.pricing?.packageComparison?.formatted }}
                    </SplitTest>
                </template>
                <template v-else>
                    {{ comparisonPrice }}
                </template>
            </div>
            <ProductDetailsPriceEcoChargeInfo v-if="ecoChargePrice"
                                              :selected-variant="selectedVariant"/>
            <ProductDetailsPriceAdditionalShippingInfo
                v-if="isSticky && additionalShippingInfo && isEcomMarket"
                :details="details"
                class="font-gibson text-12 font-light leading-16 text-brown-60"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ProductDetailsPriceAdditionalShippingInfo from '@/project/product/details/ProductDetailsPriceAdditionalShippingInfo.vue';
import ProductDetailsPriceEcoChargeInfo from '@/project/product/details/ProductDetailsPriceEcoChargeInfo.vue';
import { v4 } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import SplitTest from '@/project/growthbook/SplitTest.vue';

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
    isSticky?: boolean;
}>();

const additionalShippingInfo = computed(() => props.details.additionalShippingInfo);
const unitPrice = computed(() => props.selectedVariant.pricing.unit.formatted);
const comparisonPrice = computed(() => props.selectedVariant.pricing.comparison?.formatted);
const ecoChargePrice = computed(() => props.selectedVariant.pricing.ecoCharge?.formatted);
const isEcomMarket = computed(() => serverContext.checkoutContext.hasCheckout);
</script>
