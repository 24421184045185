<template>
    <div>
        <SplitTest :feature-key="featureKey"
                   :default-variation="defaultVariation"
                   is-fallback>
            <ProductDetailsInformation_Default :details="details"
                                               :selected-variant="selectedVariant"/>
        </SplitTest>
        <SplitTest :feature-key="featureKey"
                   :default-variation="defaultVariation"
                   variation="action">
            <ProductDetailsInformation_Action :details="details"
                                              :selected-variant="selectedVariant"/>
        </SplitTest>
        <SplitTest :feature-key="featureKey"
                   :default-variation="defaultVariation"
                   variation="action_color">
            <!-- The color change on the Læg i kurv button is handled locally in ProductDetailsAddToCartButton -->
            <ProductDetailsInformation_Action :details="details"
                                              :selected-variant="selectedVariant"/>
        </SplitTest>
        <SplitTest :feature-key="featureKey"
                   :default-variation="defaultVariation"
                   variation="immersion">
            <ProductDetailsInformation_Immersion :details="details"
                                                 :selected-variant="selectedVariant"/>
        </SplitTest>
    </div>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';
import SplitTest from '@/project/growthbook/SplitTest.vue';
import ProductDetailsInformation_Default from './ProductDetailsInformation_Default.vue';
import ProductDetailsInformation_Action from './ProductDetailsInformation_Action.vue';
import ProductDetailsInformation_Immersion from './ProductDetailsInformation_Immersion.vue';

const featureKey = 'Fsst-11818_pdp_module_order';
const defaultVariation = 'default';

defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
}>();

</script>

